
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocalStorage, useSessionStorage } from 'react-use';
import { Box, Button, Center, Link, Flex, Text, Spinner } from '@chakra-ui/react';
import sum from 'lodash/sum';

import BackgroundImage from '../../components/BackgroundImage';
import { responsive } from '../../contexts/responsive';
import up from './up.png'
import down from './down.png'
import female from './female.png'
import useOrderData from '../../hooks/useOrderData';

const texts = [
  [
    '方案內容將會寄到你的Email信箱，\n完成「跳養分析」後，\n便會收到完整的教養建議。',
    '預約連結將會寄到你的Email信箱，\n記得要先完成「跳養分析」，\n專家才能提供最合適的建議喔！',
    '訂閱通知將會寄到你的Email信箱，\n第一期生活玩案將於2022/7/1發行，\n屆時請記得到信箱確認喔！',
  ],
  [
    '方案內容、預約連結\n將會寄到你的Email信箱，\n記得要先完成「跳養分析」，\n才會收到完整的教養建議，\n專家也才能提供最合適的諮詢服務喔！',
    '方案內容將會寄到你的Email信箱，\n記得要先完成「跳養分析」，\n才會收到完整的教養建議！\n\n生活玩案的訂閱通知\n將會寄到你的Email信箱，\n第一期生活玩案將於2022/7/1發行，\n屆時請記得到信箱確認喔！',
    '預約連結將會寄到你的Email信箱，\n記得要先完成「跳養分析」，\n專家才能提供最合適的諮詢服務喔！\n\n生活玩案的訂閱通知\n將會寄到你的Email信箱，\n第一期生活玩案將於2022/7/1發行，\n屆時請記得到信箱確認喔！'
  ],
  '方案內容、預約連結\n將會寄到你的Email信箱，\n記得要先完成「跳養分析」，\n才會收到完整的教養建議，\n專家也才能提供最合適的諮詢服務喔！\n\n生活玩案的訂閱通知\n將會寄到你的Email信箱，\n第一期生活玩案也將於2022/7/1發行，請記得到信箱確認！'
]

const Thank = () => {
  const eventSent = useRef()
  const data = useOrderData()
  const [, updateCart] = useSessionStorage('cart', [])
  useEffect(() => updateCart([]), [])
  const [buy] = useLocalStorage('buy', [])
  const text = useMemo(() => {
    if (buy.length == 3) return texts[buy.length - 1]
    if (buy.length == 2) {
      const index = sum(buy.map(d => +d.no)) - 1
      return texts[buy.length - 1][index]
    }
    if (buy.length == 1) return texts[buy.length - 1][buy[0].no]
  }, [buy])
  const hasUrl = process.env.NODE_ENV === 'production' ? data?.analyzeUrl : (!buy.every(d => d.no == 2))
  const renderText = useMemo(() => {
    if (data?.line_items.length == 3) return texts[data?.line_items.length - 1]
    if (data?.line_items.length == 2) {
      const index = sum(data?.line_items.map(d => d.product_id - 10)) - 1
      return texts[data?.line_items.length - 1][index]
    }
    if (data?.line_items.length == 1) return texts[data?.line_items.length - 1][data?.line_items[0].product_id - 10]
  }, [data?.line_items])
  useEffect(() => {
    if (typeof window.gtag === 'function' && !eventSent.current && data?.line_items) {
      window.gtag('event', 'purchase', {
        currency: 'TWD',
        transaction_id: data.number,
        value: data.total,
        items: data.line_items.map(item => ({
          item_id: `${item.product_id}`,
          item_name: item.name,
        })),
      })
      eventSent.current = true
    }
  }, [data])
  return (
    <Box bg="custom.skin" maxW="36em" mx="auto" pos="relative" overflow={'hidden'}>
      <Box pointerEvents={'none'} pos="absolute" top="0" left="0" right="0">
        <BackgroundImage src={up} ratio={750 / 460} />
      </Box>
      <Box pointerEvents={'none'} pos="absolute" bottom="0" left="0" right="0">
        <BackgroundImage src={down} ratio={750 / 280} />
      </Box>
      {process.env.NODE_ENV === 'production' && !data ? (
        <Center height="100vh"><Spinner /></Center>
      ) : (
        <Center textAlign={'center'} flexDirection={'column'} minHeight="100vh" pos="relative">
          <Text fontSize={responsive('1.375em', '1.625em')} fontWeight="bold">付款完成</Text>
          <Text mt="1.125rem" fontSize={responsive('0.875em', '1.125em')} whiteSpace="pre-wrap">
            {process.env.NODE_ENV === 'production' ? renderText : text}
            <Text mt="0.25rem" fontWeight={'bold'}>
              ＊若是沒找到信件，請記得到垃圾或<br />促銷信件夾確認信件＊
            </Text>
          </Text>
          {hasUrl && (
            <Button as={Link} href={hasUrl} target="_blank" mt="1.375rem" width={responsive('12.75rem', '18.375rem')}>
              <Flex alignItems={'center'}>
                <Box width="2.25em" mr="0.5rem"><BackgroundImage src={female} ratio={1} /></Box>
                進入跳養分析
              </Flex>
            </Button>
          )}
          <Text mt="1.5rem" fontSize={responsive('0.875em', '1.125em')}>
            若沒有收到通知<br />
            請聯絡：service@kiddeveloping.com<br />
            專人諮詢：02-2325-5685
          </Text>
        </Center>
      )}

    </Box>
  )
}

export default Thank;
